export const useGetErrors = () => {
  const getErrors = (error) => {
    const errors = {}

    error.origin.messages.forEach(message => {
      if (message.field) {
        errors[message.field] = message.text
      }
      else {
        errors.common = message.text
      }
    })
    return errors
  }

  return { getErrors }
}
