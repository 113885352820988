import './nullType'

import VueTypes from 'vue-types'

VueTypes.sensibleDefaults = {
  ...VueTypes.sensibleDefaults,
  bool: false,
}

export default VueTypes
