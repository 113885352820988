<template>
  <AuthLayout :title="locale.title">
    <template #before-content>
      <div class="text-s-regular">
        {{ locale.description }}
      </div>
    </template>

    <AuthResetPasswordForm />

    <template #footer>
      <div class="text-center text-m-regular">
        <router-link :to="{name: 'auth-partner-signin'}">
          <TextLink>{{ locale.partnerSignIn }} </TextLink>
        </router-link>
      </div>
    </template>
  </AuthLayout>
</template>

<script setup>
import TextLink from 'components/buttons/TextLink'
import AuthLayout from 'components/layouts/AuthLayout'
import { useLocale } from 'lib/helpers/useLocale'
import { computed } from 'vue'

import AuthResetPasswordForm from '@/views/Auth/components/AuthResetPasswordForm'

const locale = computed(() => ({
  title: useLocale('title'),
  description: useLocale('description'),
  partnerSignIn: useLocale('partnerSignIn'),
}))
</script>
