<template>
  <div class="auth-layout">
    <div
      class="headline auth-layout__title"
      v-html="title"
    />

    <div
      v-if="$slots['before-content']"
      class="auth-layout__before-content"
    >
      <slot name="before-content" />
    </div>

    <div class="auth-layout__content">
      <slot />
    </div>

    <div
      v-if="$slots['after-content']"
      class="auth-layout__after-content"
    >
      <slot name="after-content" />
    </div>

    <div
      v-if="$slots['footer']"
      class="auth-layout__footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: VueTypes.string.isRequired,
  },
}
</script>

<style lang="scss" scoped>
.auth-layout {
  &__title {
    margin-bottom: 32px;
  }

  &__before-content {
    margin-bottom: 16px;
  }

  &__after-content {
    margin-top: 16px;

    &:empty {
      display: none;
    }
  }

  &__footer {
    margin-top: 32px;

    @media (max-width: $--large-mobile) {
      margin-top: 24px;
    }
  }
}
</style>
